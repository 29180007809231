<template>
<div class="text-center">
    <v-container><pageNotFound /></v-container>
    <h1 style="font-weight:800">الصفحة المطلوبة غير موجودة</h1><br>
    <v-btn @click="goBack()" color="primary" large="">
        <h3>رجوع</h3>
        <v-icon class="mr-2">mdi-arrow-left</v-icon>
    </v-btn>
</div>
</template>

<script>
import PageNotFound from "@/assets/animation/404";
export default {
    components: {
        PageNotFound,
    },
    methods: {
        goBack() {
            window.history.go(-1)
        },
    }
}
</script>
